import React from "react";
import { ParagraphRichText } from "../../paragraphs/ParagraphRichText/ParagraphRichText";
import { ParagraphQuote } from "../../paragraphs/ParagraphQuote/ParagraphQuote";
import { ParagraphKeyFigures } from "../../paragraphs/ParagraphKeyFigures/ParagraphKeyFigures";
import { ParagraphAccordion } from "../../paragraphs/ParagraphAccordion/ParagraphAccordion";
import { ParagraphBox } from "../../paragraphs/ParagraphBox/ParagraphBox";
import { ParagraphDownloads } from "../../paragraphs/ParagraphDownloads/ParagraphDownloads";
import { ParagraphEmbed } from "../../paragraphs/ParagraphEmbed/ParagraphEmbed";
import { ParagraphGallery } from "../../paragraphs/ParagraphGallery/ParagraphGallery";
import { ParagraphSlider } from "../../paragraphs/ParagraphSlider/ParagraphSlider";

const components = {
  paragraph__rich_text: ParagraphRichText,
  paragraph__quote: ParagraphQuote,
  paragraph__key_figures: ParagraphKeyFigures,
  paragraph__accordion: ParagraphAccordion,
  paragraph__box: ParagraphBox,
  paragraph__downloads: ParagraphDownloads,
  paragraph__embed: ParagraphEmbed,
  paragraph__gallery: ParagraphGallery,
  paragraph__slider: ParagraphSlider
};

export const getParagraph = node => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type];
    return <ParagraphComponent key={node.id} node={node} />;
  }
  return <p key={node.id}>Unknown type {node.__typename}</p>;
};
