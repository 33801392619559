import React from "react";
import { graphql } from "gatsby";
import { SliderWrapper } from "./ParagraphSlider.styles";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

export const ParagraphSlider = ({ node }) => {
  const images = node.relationships.field_images_multiple;
  return (
    <SliderWrapper>
      <Splide
        options={{
          rewind: true,
          gap: "1rem",
        }}
      >
        {images.map((image) => (
          <SplideSlide key={image.id}>
            <img
              src={
                image.relationships.field_media_image.image_style_uri
                  .square_500x500
              } alt={image.name}
            />
          </SplideSlide>
        ))}
      </Splide>
    </SliderWrapper>
  );
};

export const fragment = graphql`
  fragment ParagraphSlider on paragraph__slider {
    id
    relationships {
      field_images_multiple {
        id
        relationships {
          field_media_image {
            image_style_uri {
              square_500x500
              max_1300x1300
            }
          }
        }
        name
      }
    }
  }
`;

export default ParagraphSlider;
