import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { QuoteWrapper } from "./ParagraphQuote.styles";


export const ParagraphQuote = ({ node }) => (
  <QuoteWrapper>
  <blockquote dangerouslySetInnerHTML={{ __html: node.text.processed }} />
  <figcaption>
  <Img fixed={node.relationships.field_image_media.relationships.field_media_image.localFile.childImageSharp.fixed} />
  The author...</figcaption>
</QuoteWrapper>
);

export const fragment = graphql`
  fragment ParagraphQuote on paragraph__quote {
    id
    relationships {
      field_image_media {
        relationships {
          field_media_image {
            localFile {
              childImageSharp {
                fixed(width: 125, height: 125) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    name: field_name_function
    text: field_content {
      processed
      value
    }
  }
`;

export default ParagraphQuote;
