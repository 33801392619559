import styled from 'styled-components';


export const KeyFiguresWrapper = styled.div`
display:flex;
justify-content:space-between;
margin:50px 100px;
.figure {
    text-align:center;
}
.big {
    font-size:74px;
}

`