import styled from 'styled-components';


export const SliderWrapper = styled.figure`
margin:100px 0;

img {
    max-width:100%;
    width:100%;
    height:auto;
}
blockquote {
    margin:30px 0;
    font-style:italic;
    font-size:30px;
}
figcaption {
    display:flex;
    img {margin-right:20px;}
}
`