import React from "react";
import { graphql } from "gatsby";
import { GalleryWrapper } from "./ParagraphGallery.styles";

export const ParagraphGallery = ({ node }) => {
  const images = node.relationships.field_images_multiple
  return (
  <GalleryWrapper columns={node.field_columns}>
    {images.map((image) => (
      <img key={image.id} src={image.relationships.field_media_image.image_style_uri.square_500x500} alt={image.name} />
    ))}
  </GalleryWrapper>
)};

export const fragment = graphql`
  fragment ParagraphGallery on paragraph__gallery {
    id
    field_columns
    relationships {
      field_images_multiple {
        id
        relationships {
          field_media_image {
            image_style_uri {
              square_500x500
              max_1300x1300
            }
          }
        }
        name
      }
    }
  }
`;

export default ParagraphGallery;
