import styled from 'styled-components';


export const QuoteWrapper = styled.figure`

margin:100px;
img {
    border-radius:100px;
    margin-bottom:20px;
}
blockquote {
    margin:30px 0;
    font-style:italic;
    font-size:30px;
}
figcaption {
    display:flex;
    img {margin-right:20px;}
}
`