import styled from 'styled-components';


export const GalleryWrapper = styled.figure`
display:grid;
grid-template-columns:${props => props.columns === '3col' ? "repeat(3, 1fr)" : "repeat(2, 1fr)"};
margin:100px 0;
gap:20px;
img {
    max-width:100%;
    width:100%;
    height:auto;
}
blockquote {
    margin:30px 0;
    font-style:italic;
    font-size:30px;
}
figcaption {
    display:flex;
    img {margin-right:20px;}
}
`