import React from "react";
import { graphql } from "gatsby";

export const ParagraphRichText = ({ node }) => (
  <div className="paragraph_richtext">
  <div className="body" dangerouslySetInnerHTML={{ __html: node.text.processed }} />
  </div>
);

export const fragment = graphql`
  fragment ParagraphRichText on paragraph__rich_text {
    id
    text: field_content {
      processed
      value
    }
  }
`;

export default ParagraphRichText
