import React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "/src/components/layouts/default"
import Seo from "/src/components/helpers/seo";
import { getParagraph } from "/src/components/helpers/ParagraphRender/ParagraphRender";

const details = ({ data }) => {
  const paragraphs = data.nodeDetailPage.relationships.paragraphs.map(getParagraph);
  const post = data.nodeDetailPage;
  const langPrefix = post.langcode === 'en' ? '' : '/'+post.langcode;
  return (
    <Layout>
     <Seo title={post.title} />
    <div className="langSwitcher">
    <Link to={langPrefix + '/'}>HOME</Link>
    {post.langcode !== 'en' && data.english !== null && <Link to={data.english.path.alias}>English</Link>}
    {post.langcode !== 'pl' && data.polish !== null && <Link to={'/pl' + data.polish.path.alias}>Polish</Link>}
    {post.langcode !== 'fr' && data.french !== null && <Link to={'/fr' + data.french.path.alias}>French</Link>}
    </div>
      <h1>{post.title}</h1>
      <small>
        <em>{Date(post.created)}</em>
      </small>
      {paragraphs}
      
    </Layout>
  );
};

export default details;

export const query = graphql`
  query ($drupal_internal__nid: Int, $langcode: String) {
    nodeDetailPage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: $langcode }
    ) {
      langcode
      title
      created
      relationships {
        paragraphs: field_widgets {
          type: __typename
          ...ParagraphRichText
          ...ParagraphQuote
          ...ParagraphKeyFigures
          ...ParagraphAccordion
          ...ParagraphBox
          ...ParagraphDownloads
          ...ParagraphEmbed
          ...ParagraphGallery
          ...ParagraphSlider
        }
      }
      path {
        alias
      }
      
    }
    english: nodeDetailPage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "en" }
    ) {
      path {
        alias
      }
    }
    polish: nodeDetailPage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "pl" }
    ) {
      path {
        alias
      }
    }
    french: nodeDetailPage(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "fr" }
    ) {
      path {
        alias
      }
    }
  }
`;
