import React from "react";
import { graphql } from "gatsby";
import { KeyFiguresWrapper } from "./ParagraphKeyFigures.styles";

export const ParagraphKeyFigures = ({ node }) => {
  const figures = node.relationships.field_ref_key_figures_element;
  // console.log(figures);
  return (
  <KeyFiguresWrapper>
  {figures.map((figure, index) => (
        <div key={index} className="figure">
        <div className="big">{figure.title}</div>
        <div className="short">{figure.short}</div>
        </div>
      ))}
  </KeyFiguresWrapper>
)};

export const fragment = graphql`
  fragment ParagraphKeyFigures on paragraph__key_figures {
    id
    relationships {
      field_ref_key_figures_element {
        title:field_title
        short:field_text_short
      }
    }
  }
`;

export default ParagraphKeyFigures
