import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

const DownloadWrapper = styled.div`
  padding: 50px;
  border: 2px solid black;
  max-width: 600px;
  margin: 50px auto;
  a {
    color:black;
    text-decoration:none;
    cursor:pointer;
    &:hover {
      text-decoration:underline;
    }
  }
`;

export const ParagraphDownloads = ({ node }) => {
  const file = node.relationships.field_documents[0].relationships.field_media_document;
  return (
  <DownloadWrapper className="paragraph_downloads">
    <a href={file.localFile.absolutePath} download>
    {file.filename} - ({file.filesize})
    </a>
  </DownloadWrapper>
)};

export const fragment = graphql`
  fragment ParagraphDownloads on paragraph__downloads {
    id
    relationships {
      field_documents {
        relationships {
          field_media_document {
            filename
            filesize
            localFile {
              absolutePath
            }
          }
        }
      }
    }
  }
`;

export default ParagraphDownloads;
