import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

const BoxWrapper = styled.div`
padding:50px;
border:2px solid black;
max-width:600px;
margin:50px auto;
`

export const ParagraphBox = ({ node }) => (
  <BoxWrapper className="paragraph_box">
  <h2>{node.title}</h2>
  <div className="body" dangerouslySetInnerHTML={{ __html: node.text.processed }} />
  </BoxWrapper>
);

export const fragment = graphql`
  fragment ParagraphBox on paragraph__box {
    id
    title: field_title
    text: field_content {
      processed
      value
    }
  }
`;

export default ParagraphBox
