import React from "react";
import { graphql } from "gatsby";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {AccordionWrapper} from './ParagraphAccordion.styles';

export const ParagraphAccordion = ({ node }) => {
  const accordions = node.relationships.field_ref_accordion_element;
  return (
  <AccordionWrapper>
  <Accordion allowZeroExpanded={true}>
  {accordions.map((acc, index) => (
            <AccordionItem key={index}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    {acc.field_title}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel dangerouslySetInnerHTML={{ __html: acc.field_content.processed }}>
                </AccordionItemPanel>
            </AccordionItem>
  ))}
        </Accordion>
  </AccordionWrapper>
)};

export const fragment = graphql`
  fragment ParagraphAccordion on paragraph__accordion {
    id
    relationships {
        field_ref_accordion_element {
          field_title
          field_open_default
          field_content {
            processed
          }
        }
      }
  }
`;

export default ParagraphAccordion
