import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

const IframeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin:50px auto;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ParagraphEmbed = ({ node }) => (
  <IframeWrapper
    className="paragraph_embed"
    dangerouslySetInnerHTML={{ __html: node.text.value }}
  />
);

export const fragment = graphql`
  fragment ParagraphEmbed on paragraph__embed {
    id
    text: field_textarea {
      value
    }
  }
`;

export default ParagraphEmbed;
